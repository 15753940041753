import { Alert, Snackbar, Stack } from '@mui/material';

import { SnackbarSeverity } from 'features/redux/slices/snackbarSlice';

interface Props {
  open: boolean;
  severity: SnackbarSeverity;
  message: string;
  handleClose: () => void;
}

const SnackbarCollection = ({ open, severity, message, handleClose }: Props) => {
  const isValidSeverity = ['success', 'error', 'warning', 'info'].includes(severity);

  return (
    <Stack sx={{ width: '100%' }}>
      {isValidSeverity && (
        <Snackbar
          style={{ left: '50%', transform: 'translateX(-50%)' }}
          open={open}
          autoHideDuration={3000}
          onClose={handleClose}
        >
          <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
            {message}
          </Alert>
        </Snackbar>
      )}
    </Stack>
  );
};

export default SnackbarCollection;
